import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import { PaymentSection } from '../../components/sections';
import { CountryCode } from '../../utils/constants';

const PaymentPage = (): JSX.Element => {
  return (
    <Layout enablePage hideHeader hideFooter countryCode={CountryCode.SG}>
      <SEO title="Make Payment" />
      <PaymentSection />
    </Layout>
  );
};

export default PaymentPage;
